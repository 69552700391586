import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
// import {VscDebugBreakpointData} from 'react-icons/vsc'

function BlockHomeTwo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-4/5 mx-auto md:py-32 py-10">
        <div className="md:flex md:flex-row flex flex-col md:p-0 px-2 content-reverse">
          <div className="md:w-[50%] relative flex md:justify-end justify-center">
            <div
              className="w-[48%] md:h-[350px] h-[350px] bg-cover bg-center rounded-[60px] -left-10 md:left-0 bottom-8 absolute mt-5 border-[15px] border-white"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[4]}")` }}
            ></div>
            <div
              className="w-[80%]  md:h-[600px] h-[350px] bg-cover bg-no-repeat bg-center rounded-3xl"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[5]}")` }}
            ></div>
            
          </div>

          <div className="md:w-[50%] py-4 md:px-8 px-3 self-center md:text-start text-center">
            <h2 className="separator-about">{rpdata?.dbSlogan?.[4].slogan}</h2>
            <p className="pb-4">{rpdata?.dbHome?.[1].text}</p>
            <ButtonContent  />
            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}


export default BlockHomeTwo;
